const rules = {
  cmpyName: [
    {
      required: true,
      message: "请输入填报单位"
    },
    {
      max: 200,
      message: "填报单位最多200字"
    }
  ],
  contactPhone: [
    {
      required: true,
      message: "请输入联系方式"
    },
    {
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: "请检查手机号格式"
    }
  ],
  userName: [
    {
      required: true,
      message: "请输入填报人"
    },
    {
      max: 20,
      message: "填报人最多20字"
    }
  ],

  detailDTOS: {
    type: "array",
    required: true,
    defaultField: {
      type: "object",
      fields: {
        villageName: [
          {
            required: true,
            message: "请输入小区名称"
          },
          {
            max: 200,
            message: "小区名称最多200字"
          }
        ],
        villageAddress: [
          {
            required: true,
            message: "请输入小区位置"
          },
          {
            max: 200,
            message: "最多200字"
          }
        ],
        gcj02Lat: [
          {
            required: true,
            message: "请进行小区定位"
          }
        ],
        gcj02Lon: [
          {
            required: true,
            message: "请进行小区定位"
          }
        ],
        totalNum: [
          {
            type: "integer",
            required: true,
            max: 9999999,
            message: "住宅总套数必须大于0的整数"
          },
          {
            validator: function (rule, value, callback) {
              if (value < 0) {
                callback("住宅总套数必须大于0的整数");
              } else {
                callback();
              }
            }
          }
        ],
        rentNum: [
          {
            type: "integer",
            required: true,
            max: 9999999,
            message: "可租套数必须大于0的整数"
          },
          {
            validator: function (rule, value, callback) {
              if (value < 0) {
                callback("可租套数必须大于0的整数");
              } else {
                callback();
              }
            }
          }
        ],
        officeName: [
          {
            required: true,
            message: "请选择所在办事处"
          }
        ],

        houseTypes: {
          type: "array",
          required: true,
          defaultField: {
            type: "object",
            fields: {
              roomNum: [
                {
                  type: "integer",
                  required: true,
                  max: 9999999,
                  message: "室数量必须大于0的整数"
                },
                {
                  validator: function (rule, value, callback) {
                    if (value < 0) {
                      callback("室数量必须大于0的整数");
                    } else {
                      callback();
                    }
                  }
                }
              ],
              livingRooms: [
                {
                  type: "integer",
                  required: true,
                  max: 9999999,
                  message: "厅数量必须大于0的整数"
                },
                {
                  validator: function (rule, value, callback) {
                    if (value < 0) {
                      callback("厅数量必须大于0的整数");
                    } else {
                      callback();
                    }
                  }
                }
              ],
              toiletNum: [
                {
                  type: "integer",
                  required: true,
                  max: 9999999,
                  message: "卫数量必须大于0的整数"
                },
                {
                  validator: function (rule, value, callback) {
                    if (value < 0) {
                      callback("卫数量必须大于0的整数");
                    } else {
                      callback();
                    }
                  }
                }
              ],
              areas: [
                {
                  type: "number",
                  required: true,
                  max: 9999999,
                  message: "请输入面积"
                },
                {
                  validator: function (rule, value, callback) {
                    if (value < 0) {
                      callback("面积必须大于0");
                    } else {
                      callback();
                    }
                  }
                }
              ]
            }
          }
        }
      }
    }
  }
};

export const districts = [
  {
    value: "IT产业园社区服务中心",
    label: "IT产业园社区服务中心"
  },
  {
    value: "新港办事处",
    label: "新港办事处"
  },
  {
    value: "郑港办事处",
    label: "郑港办事处"
  },
  {
    value: "滨河办事处",
    label: "滨河办事处"
  },
  {
    value: "银河办事处",
    label: "银河办事处"
  },
  {
    value: "张庄办事处",
    label: "张庄办事处"
  },
  {
    value: "三官庙办事处",
    label: "三官庙办事处"
  },
  {
    value: "龙港办事处",
    label: "龙港办事处"
  },
  {
    value: "八岗办事处",
    label: "八岗办事处"
  },
  {
    value: "清河办事处",
    label: "清河办事处"
  },
  {
    value: "龙王办事处",
    label: "龙王办事处"
  },
  {
    value: "明港办事处",
    label: "明港办事处"
  },
  {
    value: "冯堂办事处",
    label: "冯堂办事处"
  },
  {
    value: "八千办事处",
    label: "八千办事处"
  },
  {
    value: "岗李乡",
    label: "岗李乡"
  },
  {
    value: "大马乡",
    label: "大马乡"
  },
  {
    value: "大营镇",
    label: "大营镇"
  },
  {
    value: "洧川镇",
    label: "洧川镇"
  },
  {
    value: "其他",
    label: "其他"
  }
];

export default rules;
