<template>
  <!-- 出租房屋数量统计 -->
  <section class="form-wrapper">
    <div>
      <van-form ref="form">
        <div class="header card">
          <div class="title">航空港区居民小区可出租房屋数量统计表</div>
          <div class="notices">
            <p class="notice-item">*填写说明</p>
            <p class="notice-item">
              1、上报人负责多个小区时，可通过点击
              <span class="link">”新增房源”</span>
              ，进行多个小区房源上报
            </p>
            <p class="notice-item">
              2、上报人负责单个小区内有多种户型时，可通过点击
              <span class="link">“新增户型”</span>
              ，补充完善小区户型信息。
            </p>
          </div>
          <van-field
            v-model.trim="form.cmpyName"
            label="填报单位"
            placeholder="请输入填报单位"
            required
            maxlength="200"
          />
          <van-field
            v-model.trim="form.userName"
            label="填报人"
            placeholder="请输入填报人"
            required
            maxlength="20"
          />
          <van-field
            v-model.trim="form.contactPhone"
            label="联系方式"
            placeholder="请输入联系方式"
            required
            maxlength="11"
            readonly
            disabled
          />
        </div>

        <!--  房屋信息  -->
        <div>
          <div
            v-for="(item, i) in form.detailDTOS"
            :key="i"
            class="plot-wrapper card"
          >
            <p class="plot-title">
              <span> # {{ i + 1 }} </span>
              <span
                @click="handleDelPlot(i)"
                v-show="form.detailDTOS.length > 1"
                class="del-btn"
              >
                删除房源
              </span>
            </p>
            <van-field
              v-model.trim="item.villageName"
              label="小区名称"
              placeholder="请输入小区名称"
              maxlength="200"
              required
            />
            <!-- <van-field
              v-model="item.villageAddress"
              label="小区位置"
              placeholder="请输入小区位置"
              maxlength="20"
              required
            /> -->
            <area-select
              v-model.trim="item.villageAddress"
              :options="districts"
              :fieldProps="{
                label: '小区位置',
                required: true,
                placeholder: '请输入小区位置'
              }"
              @poiSelect="(e) => handlePoiSelect(i, e)"
            ></area-select>
            <van-field
              v-model.number="item.totalNum"
              label="住宅总套数/套"
              placeholder="请输入住宅总套数/套"
              required
            />
            <van-field
              v-model.number="item.rentNum"
              label="已装修,可供出租套数/套"
              placeholder="请输入已装修,可供出租套数/套"
              required
            />
            <van-field-select
              v-model="item.officeName"
              :options="districts"
              :fieldProps="{
                label: '所在办事处',
                required: true,
                placeholder: '请选择所在办事处'
              }"
            ></van-field-select>
            <div class="house-wrapper">
              <div class="house-title">
                <span>户型类型</span>
                <span @click="handleAddHouseType(i)" class="add-btn">
                  新增户型
                </span>
              </div>
              <div v-for="(house, j) in item.houseTypes" class="house-form">
                <p class="house-form-title">
                  <span>户型{{ j + 1 }}</span>
                </p>
                <van-row>
                  <van-col :span="12">
                    <van-field
                      v-model.number="house.roomNum"
                      label="室"
                      required
                      label-width="30"
                      placeholder="室数量"
                    />
                  </van-col>
                  <van-col :span="12">
                    <van-field
                      v-model.number="house.livingRooms"
                      label="厅"
                      required
                      label-width="30"
                      placeholder="厅数量"
                    />
                  </van-col>
                  <van-col :span="12">
                    <van-field
                      v-model.number="house.toiletNum"
                      label="卫"
                      required
                      label-width="30"
                      placeholder="卫数量"
                    />
                  </van-col>
                  <van-col :span="12">
                    <van-field
                      v-model.number="house.areas"
                      label="面积"
                      required
                      label-width="30"
                      placeholder="面积（平方米）"
                    />
                  </van-col>
                  <van-col>
                    <span
                      v-show="form.detailDTOS[i].houseTypes.length > 1"
                      @click="handleDelHouseType(i, j)"
                      class="del-btn del-house-btn"
                    >
                      删除户型
                    </span>
                  </van-col>
                </van-row>
              </div>
            </div>

            <!-- <p class="fam-btns">
              <van-button block plain icon="plus" type="primary" size="small">
                新增户型
              </van-button>
            </p> -->
            <!-- <p class="fam-btns">
              <van-button
                class="btn"
                type="danger"
                size="mini"
                @click="onDelete(i)"
              >
                删除房源
              </van-button>
            </p> -->
          </div>
          <div>
            <van-button
              block
              plain
              icon="plus"
              type="primary"
              size="small"
              @click="handleAddPlot"
            >
              新增房源
            </van-button>
          </div>
        </div>
      </van-form>
    </div>
  </section>
</template>

<script>
import Schema from "async-validator";
import formRules from "./rules";
import { districts } from "./rules";
import VanFieldSelect from "@/components/VanFieldSelect";
import cloneDeep from "lodash/cloneDeep";
import AreaSelect from "../../components/AreaSelect.vue";
import Cookies from "js-cookie";
import { Dialog } from "vant";
//户型表单
const houseTypeForm = {
  areas: "",
  livingRooms: "",
  roomNum: "",
  toiletNum: ""
};

//小区表单
const plotForm = {
  gcj02Lat: "",
  gcj02Lon: "",
  houseTypes: [cloneDeep(houseTypeForm)],
  officeName: "",
  rentNum: "",
  totalNum: "",
  villageAddress: "",
  villageName: ""
};
export default {
  data() {
    return {
      districts: districts,
      form: {
        cmpyName: "",
        contactPhone: "",
        userName: "",
        detailDTOS: [cloneDeep(plotForm)]
      }
    };
  },
  props: {
    defaultForm: Object
  },
  components: { VanFieldSelect, AreaSelect },
  computed: {},
  mounted() {
    if (this.defaultForm) {
      this.form = this.defaultForm;
    }
    const authPhone = Cookies.get("HouseSurveyPhone") || "";
    if (authPhone) {
      this.form.contactPhone = authPhone;
    }
  },
  methods: {
    handleAddPlot() {
      this.form.detailDTOS.push(cloneDeep(plotForm));
    },
    handleDelPlot(index) {
      if (this.form.detailDTOS.length == 1) {
        this.$toast("最后一条不能删除");
        return;
      }
      Dialog.confirm({
        title: "提示",
        message: "确认要删除此房源吗？"
      }).then(() => {
        this.form.detailDTOS.splice(index, 1);
      });
    },
    handleAddHouseType(i) {
      this.form.detailDTOS[i].houseTypes.push(cloneDeep(houseTypeForm));
    },
    handleDelHouseType(i, j) {
      Dialog.confirm({
        title: "提示",
        message: "确认要删除此户型吗？"
      }).then(() => {
        this.form.detailDTOS[i].houseTypes.splice(j, 1);
      });
    },
    handlePoiSelect(i, poi) {
      this.form.detailDTOS[i].villageAddress = `${poi.address} ${poi.name}`;
      this.form.detailDTOS[i].gcj02Lat = `${poi.location.lat}`;
      this.form.detailDTOS[i].gcj02Lon = `${poi.location.lng}`;
    },
    validate() {
      const validator = new Schema(formRules);
      return new Promise((resolve, reject) => {
        validator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            resolve({
              ...this.form
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #fff;
  border-radius: 0.2rem;
  padding: 0.2rem 0.3rem;
  overflow: hidden;
  margin-bottom: 0.3rem;
}
.title {
  font-size: 0.36rem;
  margin: 0.3rem 0;
  text-align: center;
  font-weight: bold;
}
.form-wrapper {
  // margin-top: 0.2rem;
  // background: #fff;
  .fam-btns {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #eee;
    .btn {
      width: 1.5rem;
      margin-right: 0.15rem;
    }
  }
}
.house-wrapper {
  padding: 0.1rem 0.15rem;
  .house-title {
    margin: 0.3rem 0;
    .add-btn {
      margin-left: 0.2rem;
    }
    // display: flex;
    // justify-content: space-between;
  }
  .house-form {
    padding-left: 0.15rem;
    overflow: auto;
    margin-bottom: 0.2rem;
    border-bottom: 1px solid #dfdfdf;
    .del-house-btn {
      display: block;
      // margin-bottom: 0.2rem;
      padding: 0.2rem 0;
    }
  }
  .house-form-title {
    margin: 0.1rem 0;
    display: flex;
    justify-content: space-between;
  }
}
.plot-wrapper {
  // padding: 0.2rem 0;
  .plot-title {
    padding: 0 0.15rem;
    display: flex;
    justify-content: space-between;
  }
}
.add-btn {
  color: #3c37d2;
}
.del-btn {
  color: red;
}

.notices {
  margin-top: 0.2rem;
  padding: 0.3rem;
  background-color: #fff;
  .notice-item {
    font-size: 0.3rem;
    line-height: 0.5rem;
    color: #ff0000;
    margin-bottom: 0.2rem;
  }
  .link {
    color: blue;
  }
}
</style>
